
import { defineComponent, ref, computed, onMounted} from "vue";
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Form",
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const form = ref(props.data);
    const getDistricts = () => {
      store.dispatch("Option/getAllDistricts");
    };

    const getRTM = () => {
      store.dispatch("Option/getAllRetailTerritoryManagers");
    };

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const districts = computed(() => store.getters["Option/all_districts"]);
    const retail_territory_managers = computed(() => store.getters["Option/all_retail_territory_managers"]);

    onMounted(getDistricts);
    onMounted(getRTM);

    return {
      form,
      districts,
      retail_territory_managers,
      filterOption
    };
  },
});
